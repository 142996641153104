.custom-alert {
  background-color: var(--alert-bg-color);
  color: var(--light-text);
  border-radius: 5px;
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 15px;
  margin-right: 15px;
}

.list-item {
  margin: 25px;
  border-bottom: 1px solid var(--minus10-info-bg-color);
  padding-bottom: 10px;
}
.list-item-text {
  margin-left: 15px;
}
.mini-list-item {
  margin-bottom: 5px;
  border-bottom: 1px solid var(--minus10-info-bg-color);
  margin-right: 25px;
}
