.icon-raised-mini {
  margin-bottom: 2px;
}
.icon-raised {
  margin-bottom: 5px;
}
.icon-nav {
  color: var(--minus20-info-bg-color);
}
.icon-tabs {
  margin-bottom: 3px;
}
.icon-tabs-selected {
  margin-bottom: 3px;
  color: var(--minus20-info-bg-color);
}
.icon-tabs-vertical {
  /* color: var(--info-bg-color); */
  margin-right: 5px;
}
.icon-tabs-vertical-selected {
  margin-right: 5px;
  color: var(--minus20-info-bg-color);
}
.loading-logo {
  height: 80px;
  width: auto;
  border-radius: 5px;
}
