.custom-table {
  border: 1px solid var(--dark-grey);
  border-collapse: collapse;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}
.table-header {
  text-decoration: underline;
}
.table-header-cell {
  padding-left: 5px;
  padding-right: 5px;
}
.table-row:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.table-row.selected {
  background-color: rgba(0, 0, 0, 0.3);
}
.table-row-alternate {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-cell {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
