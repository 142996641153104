:root {
  --light-grey: #e6e6e6;
  --medium-grey: #bfbfbf;
  --dark-grey: #999999;
  --light-text: #f2f2f2;
  --dark-text: #1a1a1a;
  --main-background: #e6ffe8;
  --light-bg-color: #ccffd0;
  --minus10-light-bg-color: #99ffa2;
  --minus20-light-bg-color: #66ff73;
  --dark-bg-color: #003304;
  --minus10-dark-bg-color: #006609;
  --minus20-dark-bg-color: #00990d;
  --info-bg-color: #eeccff;
  --minus10-info-bg-color: #dd99ff;
  --minus20-info-bg-color: #cc66ff;
  --alert-bg-color: #e60000;
  --input-box-width: 150px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable {
  cursor: pointer;
}

.main {
  background-color: var(--main-background);
  min-height: 100vh;
}

.bg-info {
  background-color: var(--info-bg-color) !important;
  color: var(--dark-text);
}
.bg-main {
  background-color: var(--main-background);
}
.bg-alert {
  background-color: var(--alert-bg-color);
  color: var(--light-text);
}
.spinner {
  color: var(--info-bg-color);
}

.pad-in {
  padding-left: 15px;
  padding-right: 15px;
}

.small-padding {
  margin-top: 5px;
  margin-bottom: 5px;
}

.true-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.StripeElement {
  width: 100%;
}

.color-dot {
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: 3px;
  border-radius: 5px;
}

.bordered-box {
  border: 1px solid var(--dark-bg-color);
  border-radius: 5px;
  padding: 3px;
}
.small-text {
  font-size: 0.8em;
}
